import React from 'react';
import { SelectedMenuItem } from './SideMenuDefinition';
import { GroupPageComponent, HomePageComponent } from './Pages';

export interface RoutePageComponentProps {
  selectedMenuItem: SelectedMenuItem
}

export interface RoutePageComponentState {
}

export class RoutePageComponent extends React.Component<RoutePageComponentProps, RoutePageComponentState> {

  componentDidMount() {
  }

  onSearch(id: string) {
    this.setState({ accountId: id })
  }

  render() {
    const { selectedMenuItem } = this.props;

    return (<>
      {selectedMenuItem.breadcrumb[0] === 'Home' && <HomePageComponent />}
      {selectedMenuItem.breadcrumb[0] === 'Groups'
        && <GroupPageComponent groupTitle={selectedMenuItem.title} groupId={selectedMenuItem.id} />}
    </>);
  }
}
