type MessageTypes = 'NewGroupAdded'
export type MessageToken = string

export class MessageBus {
    _newGroupSubscriptions: { [key: MessageToken]: () => void } = {}

    public subscribe(type: MessageTypes, onMessage: () => void): MessageToken {
        const guid = uuidv4()
        if (type === 'NewGroupAdded') {
            this._newGroupSubscriptions[guid] = onMessage
        }
        return guid
    }

    public unsubscribe(type: MessageTypes, token: MessageToken) {
        if (type === 'NewGroupAdded') {
            delete this._newGroupSubscriptions[token]
        }
    }

    public publish(type: MessageTypes) {
        if (type === 'NewGroupAdded') {
            Object.keys(this._newGroupSubscriptions)
                .forEach(subscription => {
                    this._newGroupSubscriptions[subscription]()
                })
        }
    }
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
