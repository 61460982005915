import React from 'react';
import './App.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import { SelectedMenuItem, SideMenu } from './SideMenuDefinition';
import { CancelToken } from './api/HttpClient';
import { RoutePageComponent } from './RoutePage';
import { ChoresApi, Group } from './api/ChoresApi';
import { logger } from './utils';
import { messageBus, MessageToken } from './messaging';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export interface AppComponentProps {
}

export interface AppComponentState {
  groups?: Group[]
  selectedMenuItem: SelectedMenuItem
}

export class App extends React.Component<AppComponentProps, AppComponentState> {

  constructor(props: AppComponentProps) {
    super(props)
    this.state = {
      selectedMenuItem: {
        breadcrumb: ['Home']
      }
    };
  }

  private cancelToken: CancelToken | undefined
  private _isCanceled: boolean = false

  private _token: MessageToken | undefined

  componentDidMount() {
    this.reloadGroups()

    this._token = messageBus.subscribe('NewGroupAdded', () => {
      this.reloadGroups()
    })
  }

  componentWillUnmount() {
    this.cancelReloadGroups()

    if (typeof this._token === 'string') {
      messageBus.unsubscribe('NewGroupAdded', this._token)
      this._token = undefined
    }
  }

  cancelReloadGroups() {
    this._isCanceled = true;
    this.cancelToken?.cancel();
    this.cancelToken = undefined;
  }

  reloadGroups() {
    this.cancelReloadGroups()

    this._isCanceled = false
    this.cancelToken = new CancelToken();
    ChoresApi.groups.get(this.cancelToken)
      .then(response => {
        this.setState({ groups: response.data?.results })
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get groups cancelled', err);
        }
      });
  }

  render() {
    const { selectedMenuItem, groups } = this.state
    const { menu, getMenuItem } = new SideMenu().buildMenu(groups || [])

    return <Layout>
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[]}>
          <Menu.Item key="1">Recurring Chores</Menu.Item>
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={['0']}
            defaultOpenKeys={[]}
            style={{ height: '100%', borderRight: 0 }}
            onSelect={(e) => this.setState({ selectedMenuItem: getMenuItem(e.key) })}
          >
            {menu.map((item, idx) => {
              if (item.type === 'menu') {
                return <Menu.Item key={idx} icon={item.icon}>{item.title}</Menu.Item>
              }
              return <SubMenu key={`${idx}`} icon={item.icon} title={item.title}>
                {item.menuitems?.map((menuitem, menuidx) => <Menu.Item key={`${idx}-${menuidx}`}>{menuitem.title}</Menu.Item>)}
              </SubMenu>
            })}
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {selectedMenuItem.breadcrumb.map(i => <Breadcrumb.Item key={i}>{i}</Breadcrumb.Item>)}
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            <RoutePageComponent selectedMenuItem={selectedMenuItem} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  }
}

export default App;
