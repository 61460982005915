import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { ChoresApi } from '../api/ChoresApi';
import { messageBus } from '../messaging';
import { logger } from '../utils';

const InputText = (params: { placeholder: string, buttonText: string, onSubmit: (text: string) => void }) => {

  const [text, updateText] = useState('')

  return <div>
    <Input placeholder={params.placeholder} value={text} onChange={(ev) => updateText(ev.target.value)} />
    <Button onClick={() => params.onSubmit(text)}>{params.buttonText}</Button>
  </div>
}

export interface LoggedInHomeComponentProps {
}

export interface LoggedInHomeComponentState {
}

export class LoggedInHomeComponent extends React.Component<LoggedInHomeComponentProps, LoggedInHomeComponentState> {

  constructor(props: LoggedInHomeComponentProps) {
    super(props)
    this.state = {
    };
  }

  addGroup(groupName: string) {

    ChoresApi.groups.put(groupName)
      .then(response => {
        logger.info('new group', response)
        messageBus.publish('NewGroupAdded')
      })
      .catch(err => {
        logger.warn('could not add new group', err);
      });
  }

  render() {
    return (
      <div color="light-2">
        Welcome!

        <div>
          <InputText
            placeholder="Group Name"
            buttonText="Add group"
            onSubmit={(text) => this.addGroup(text)}
          />
        </div>
      </div>
    );
  }
}
