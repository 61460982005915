import { ReactElement } from 'react';
import { UserOutlined, HomeOutlined } from '@ant-design/icons';
import { Group } from './api/ChoresApi';

interface MenuDefinition {
  type: 'menu' | 'submenu',
  title: string,
  icon?: ReactElement,
  menuitems?: { title: string, id: string }[]
}

export interface SelectedMenuItem {
  id?: string
  title?: string
  breadcrumb: string[]
}

export class SideMenu {

  buildMenu(groups: Group[]) {
    const menu: MenuDefinition[] = [{
      type: 'menu',
      title: 'Home',
      icon: <HomeOutlined />,
    }, {
      type: 'submenu',
      title: 'Groups',
      icon: <UserOutlined />,
      menuitems: groups.map(group => ({
        title: group.name,
        id: group._id,
      }))
    }]

    function getMenuItem(key: string): SelectedMenuItem {
      const breadcrumb = []

      const split = key.split('-').map(x => +x)
      let current = menu[split[0]]
      breadcrumb.push(current.title)

      if (split.length === 1 || !current.menuitems) {
        return { breadcrumb } // current
      }

      const menuItem = current.menuitems[split[1]]
      breadcrumb.push(menuItem.title)
      return { breadcrumb, id: menuItem.id, title: menuItem.title }
    }

    return { menu, getMenuItem }
  }
}
