import { CancelToken, HttpClient } from "./HttpClient";

export class ChoresApiClient extends HttpClient {
    constructor() {
        super('https://api.recurring-chores.maisiesadler.co.uk')
    }
}

export const ChoresApi = {
    accounts: (cancelToken: CancelToken) =>
        new ChoresApiClient().Get<GetAccountsResponse>('/chores/user', cancelToken),

    groups: {
        get: (cancelToken: CancelToken) =>
            new ChoresApiClient().Get<GetGroupsResponse>('/chores/group', cancelToken),
        put: (group_name: string, cancelToken?: CancelToken) =>
            new ChoresApiClient().Put<string>('/chores/group', { group_name }, cancelToken),

        id: (groupId: string) => ({
            tasks: {
                get: (cancelToken?: CancelToken) =>
                    new ChoresApiClient().Get<GetTasksResponse>(`/chores/group/${groupId}/task`, cancelToken),
                post: (updates: UpdateTaskRequest, cancelToken?: CancelToken) =>
                    new ChoresApiClient().Post<string>(`/chores/group/${groupId}/task`, updates, cancelToken),
                put: (taskTitle: string, cancelToken?: CancelToken) =>
                    new ChoresApiClient().Put<string>(`/chores/group/${groupId}/task`, { group_id: groupId, task_title: taskTitle }, cancelToken),
            }
        })
    }
}

export interface GetAccountsResponse {
    auth_url: string
}

export interface Group {
    created_by: string
    name: string
    _id: string
}

export interface Task {
    _id: string
    group_id: string
    title: string
    category?: string
    due_date?: string
    frequency_days?: number
    completed_history?: { iso_date: string }[]
}

export interface GetGroupsResponse {
    results: Group[]
}

export interface GetTasksResponse {
    results: Task[]
}

export interface UpdateTaskRequest {
    update_type: 'merge' | 'completed'
    task_id: string
    title?: string
    category?: string
    frequency_days?: number
}
