import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { ChoresApi, Task } from '../api/ChoresApi';
import { CancelToken } from '../api/HttpClient';
import { logger } from '../utils';
import { TaskComponent } from './TaskComponent';

const InputText = (params: { placeholder: string, buttonText: string, onSubmit: (text: string) => void }) => {

  const [text, updateText] = useState('')

  return <div>
    <Input placeholder={params.placeholder} value={text} onChange={(ev) => updateText(ev.target.value)} />
    <Button onClick={() => params.onSubmit(text)}>{params.buttonText}</Button>
  </div>
}

export interface GroupPageComponentComponentProps {
  groupId?: string
  groupTitle?: string
}

export interface GroupPageComponentComponentState {
  loadingGroupId?: string
  loadedGroupId?: string
  tasks?: Task[]
}

export class GroupPageComponent extends React.Component<GroupPageComponentComponentProps, GroupPageComponentComponentState> {

  private cancelToken: CancelToken | undefined
  private _isCanceled: boolean = false

  state: GroupPageComponentComponentState = { tasks: [] };

  componentDidMount() {
    const { groupId } = this.props
    if (typeof groupId !== 'string') return

    this.loadGroupId(groupId);
  }

  loadGroupId(groupId: string) {
    this.setState({ loadingGroupId: groupId })

    this.cancelToken?.cancel();
    this._isCanceled = false
    this.cancelToken = new CancelToken();
    ChoresApi.groups.id(groupId).tasks.get(this.cancelToken)
      .then(response => {
        this.setState({
          loadedGroupId: groupId,
          loadingGroupId: undefined,
          tasks: response.data?.results,
        })
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get group tasks cancelled', err);
        }
      });
  }

  componentWillUnmount() {
    this.cancelLoading()
  }

  cancelLoading() {
    this._isCanceled = true;
    this.cancelToken?.cancel();
    this.cancelToken = undefined;
  }

  addTask(taskTitle: string) {
    const { groupId } = this.props
    if (typeof groupId !== 'string') return

    ChoresApi.groups.id(groupId).tasks.put(taskTitle)
      .then(_ => {
        this.loadGroupId(groupId)
      })
      .catch(err => {
        if (!this._isCanceled) {
          logger.warn('get group tasks cancelled', err);
        }
      });
  }

  render() {
    const { groupId, groupTitle } = this.props
    const { tasks, loadingGroupId, loadedGroupId } = this.state

    if (typeof groupId === 'string' && groupId !== loadedGroupId && groupId !== loadingGroupId) {
      this.loadGroupId(groupId)
    }

    if (!!loadingGroupId) {
      return <div>Loading...</div>
    }

    return <div>
      <div>{groupTitle} = {groupId}</div>
      <InputText placeholder="input" buttonText="Add Task" onSubmit={text => this.addTask(text)} />
      <TaskComponent
        tasks={tasks!}
        reload={() => this.loadGroupId(groupId || '')}
      />
    </div>
  }
}
