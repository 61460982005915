import { Button, Card, Divider, Input, InputRef, Select, Space } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useRef, useState } from 'react';
import { ChoresApi, Task, UpdateTaskRequest } from '../api/ChoresApi';
import { logger } from '../utils';

const inTime: (isoDate?: string) => string = (isoDate?: string) => {
  if (typeof isoDate === 'undefined') return ''
  const date = new Date(isoDate)
  const now = new Date()
  date.setHours(12, 0, 0, 0)
  now.setHours(12, 0, 0, 0)
  const diffSecs = (date.getTime() - now.getTime()) / 1000
  const diffDays = diffSecs / (60 * 60 * 24)

  if (diffDays === 0) return 'Today'
  if (diffDays === 1) return 'Tomorrow'
  return `In ${diffDays} days`
}

const asTimeAgo: (isoDate?: string) => string = (isoDate?: string) => {
  if (typeof isoDate === 'undefined') return ''
  const date = new Date(isoDate)
  const now = new Date()
  const diffSecs = (now.getTime() - date.getTime()) / 1000

  if (diffSecs < 45) return `${Math.floor(diffSecs)} seconds ago`
  if (diffSecs / 60 < 45) return `${Math.floor(diffSecs / 60)} minutes ago`
  if (diffSecs / (60 * 60) < 24) return `${Math.floor(diffSecs / (60 * 60))} hours ago`
  return `${Math.floor(diffSecs / (60 * 60 * 24))} days ago`
}

const frequencyAsTime: (days?: number) => string = (days?: number) => {
  if (typeof days === 'undefined') return ''
  if (days % 7 === 0) return days / 7 === 1 ? '1 week' : `${days / 7} weeks`
  return `${days} days`
}

const ExpandableRow = (params: { editing: boolean, task: Task, categories: string[], onEdit: (task: Partial<Task>) => void, setTaskCompletedNow: () => void }) => {

  if (params.editing) {
    return <EditTask task={params.task} categories={params.categories} onSubmit={params.onEdit} />
  }

  return <div>
    Completed
    <Button onClick={() => params.setTaskCompletedNow()}>Set completed now</Button>
    <ul>
      {params.task.completed_history?.map(h => {
        return <li>{asTimeAgo(h.iso_date)}</li>
      })}
    </ul>
  </div>
}

const EditTask = (params: { categories: string[], task: Task, onSubmit: (task: Partial<Task>) => void }) => {

  const [title, updateTitle] = useState(params.task.title)
  const [category, setCategory] = useState(params.task.category);
  const [frequencyDays, updateFrequencyDays] = useState(params.task.frequency_days)
  const [categories, setCategories] = useState(params.categories);
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name === '') return
    setCategories([...categories, name]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return <div>
    <Input addonBefore="Title" placeholder="Update task title" value={title} onChange={(ev) => updateTitle(ev.target.value)} />
    Category
    <Select
      style={{ width: 300 }}
      placeholder="Choose category"
      value={category}
      onChange={x => setCategory(x)}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
      options={categories.map((item) => ({ label: item, value: item }))}
    />
    <Input addonBefore="Frequency (days)" type="number" placeholder="Update task frequency" value={frequencyDays} onChange={(ev) => updateFrequencyDays(+ev.target.value)} />
    <Button onClick={() => params.onSubmit({
      title,
      frequency_days: frequencyDays,
      category,
    })}>Submit</Button>
  </div>
}

const columns: ColumnsType<Task> = [
  { title: 'Title', dataIndex: 'title', key: 'title' },
  { title: 'Category', dataIndex: 'category', key: 'category' },
  {
    title: 'Due Date', dataIndex: 'due_date', key: 'due_date', render: (_, task) => {
      return <>{inTime(task.due_date)}</>
    }
  },
  {
    title: 'Frequency', dataIndex: 'due_date', key: 'due_date', render: (_, task) => {
      return <>{frequencyAsTime(task.frequency_days)}</>
    }
  },
  {
    title: 'Last completed', dataIndex: 'completed_history', key: 'completed_history', render: (_, task) => {
      const lastCompleted: string | null = !!task.completed_history && task.completed_history.length > 0
        ? asTimeAgo(task.completed_history[0].iso_date)
        : null
      return <>{lastCompleted}</>
    }
  },
];

export interface TaskComponentProps {
  tasks: Task[]
  reload: () => void
}

export interface TaskComponentState {
  editing: boolean
}

export class TaskComponent extends React.Component<TaskComponentProps, TaskComponentState> {

  state: TaskComponentState = { editing: false };

  updateTask(task: Task, updates: Partial<Task>) {
    const { reload } = this.props

    const updateTaskRequest: UpdateTaskRequest = { update_type: 'merge', task_id: task._id };
    if ('title' in updates) updateTaskRequest.title = updates.title
    if ('frequency_days' in updates) updateTaskRequest.frequency_days = updates.frequency_days
    if ('category' in updates) updateTaskRequest.category = updates.category
    ChoresApi.groups.id(task.group_id).tasks.post(updateTaskRequest)
      .then(_ => reload())
      .catch(err => {
        logger.warn('update task error', err);
      });
  }

  setTaskCompletedNow(task: Task) {
    const { reload } = this.props

    const updateTaskRequest: UpdateTaskRequest = { update_type: 'completed', task_id: task._id };
    ChoresApi.groups.id(task.group_id).tasks.post(updateTaskRequest)
      .then(_ => reload())
      .catch(err => {
        logger.warn('update task error', err);
      });
  }
  
  render() {
    const { tasks } = this.props
    const { editing } = this.state

    const categoryMap: { [key: string]: boolean } = {};
    (tasks || []).forEach(t => {
      if (!!t.category) {
        categoryMap[t.category] = true
      }
    })
    const categories = Object.keys(categoryMap)

    return <>
      <Button onClick={() => this.setState({ editing: !editing })}>Edit</Button>
      <Table
        rowKey={t => t._id}
        columns={columns}
        expandable={{
          expandedRowRender: (task) => <ExpandableRow
            categories={categories}
            editing={editing}
            task={task}
            onEdit={update => this.updateTask(task, update)}
            setTaskCompletedNow={() => this.setTaskCompletedNow(task)}
          />,
        }}
        dataSource={tasks}
      />
    </>
  }
}
